<template>
    <div class="mystore_template">
          <div v-if="isLoggedIn == true"  class="">
            
          </div>
          <div v-else>
                <span>Please Login first</span>
                <a href="/">login</a>
          </div>
    </div>
  </template>
  
  <script>
  import { mapActions,mapGetters } from "vuex";
  export default {
    name: 'HelloWorld',
     components: {
     },
      data() {
        return {
          errors:[],
        }
     },
      methods: {
           ...mapActions(["shop"]),
           ...mapGetters(["isAuthenticated","shopDetails"]), 
      },
      computed: {
        isLoggedIn : function(){
            return this.$store.getters.isAuthenticated
        },
        shoptext : function(){
            return this.$store.getters.shopDetails
        }
      },
  
  
  }
  </script>